import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "adaptive-sharing",
      "style": {
        "position": "relative"
      }
    }}>{`Adaptive Sharing`}<a parentName="h1" {...{
        "href": "#adaptive-sharing",
        "aria-label": "adaptive sharing permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Arweave has one of the most fascinating mechanisms we have yet seen: Adaptive Interactive Incentive Agents (AIIA). This comes from a close study of the BitTorrent protocol and their optimistic tit-for-tat strategy, which was challenged by an adaption called BitTyrant, and subsequently improved. Arweave has adapted this improved tit-for-tat strategy for sharing bandwidth and used it to incentivise generally pro-social behaviour.`}</p>
    <p>{`Every node operator can create their own strategy for how, when and with whom they share data in the weave. Importantly, strategies are not dictated by consensus rules. The totality of the rules expressed in the meta-game is defined by the sum of each of the individual games that are currently being played. This means there is no need for centrally enforced protocol upgrades and we are here presented with a highly scalable game structure where communication overhead on consensus is virtually non-existent.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The intention for this conversation is to look closely at this open, non-coercive mechanism, try to tease out some of its subtleties, and then use this to illustrate how careful mechanism design at the protocol level can result in really interesting applications of tokens, in this case what the Arweave team has called Profit Sharing Tokens.`}</p>
    <ol>
      <li parentName="ol">{`Read pg 40 to pg 45 of the `}<a parentName="li" {...{
          "href": "https://www.arweave.org/yellow-paper.pdf"
        }}>{`Arweave Yellow Paper`}</a></li>
      <li parentName="ol">{`Understand your `}<a parentName="li" {...{
          "href": "https://arwiki.wiki/#/en/karma"
        }}>{`karma`}</a></li>
      <li parentName="ol">{`Read about `}<a parentName="li" {...{
          "href": "https://arwiki.wiki/#/en/profit-sharing-tokens"
        }}>{`Profit Sharing Tokens`}</a>{` and `}<a parentName="li" {...{
          "href": "https://arwiki.wiki/#/en/profit-sharing-communities"
        }}>{`Profit Sharing Communities`}</a></li>
      <li parentName="ol">{`Watch `}<a parentName="li" {...{
          "href": "https://youtu.be/8ZDEc7uFi64?t=1429"
        }}>{`Sam's KB3 Fireside`}</a></li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/U7v63TjdutE" mdxType="Video" />
    <p>{`We returned to study Arweave once more in Kernel Block V:`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/n7ZcEXPfLXA" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      